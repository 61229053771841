import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/ClassAssembly/2023/5B/1.jpg';
import p2 from 'assests/Photos/ClassAssembly/2023/5B/2.jpg';
import p3 from 'assests/Photos/ClassAssembly/2023/5B/3.jpg';
import p4 from 'assests/Photos/ClassAssembly/2023/5B/4.jpg';
// import p5 from 'assests/Photos/ClassAssembly/2023/5D/5.jpg';
// import p6 from 'assests/Photos/ClassAssembly/2023/5D/6.jpg';
// import p7 from 'assests/Photos/ClassAssembly/2023/5D/7.jpg';
// import p8 from 'assests/Photos/ClassAssembly/2023/5D/8.jpg';


// import p7 from 'assests/Photos/ClassAssembly/6D/7.jpg';



import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';

const ClassAssembly5B2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    // {
    //     src: p5,
    //     source: p5,
    //     rows: 1,
    //     cols: 1,
    //   },
    //   {
    //     src: p6,
    //     source: p6,
    //     rows: 1,
    //     cols: 1,
    //   },

    //   {
    //     src: p7,
    //     source: p7,
    //     rows: 1,
    //     cols: 1,
    //   },
    //   {
    //     src: p8,
    //     source: p8,
    //     rows: 1,
    //     cols: 1,
    //   },

    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      ‘World Ocean Day’
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 5B   Date: 28 JUNE 2023 and 30 JUNE 2023

        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        “A healthy ocean is vital to our economy and well-being. Without water, our planet would be one of the billions of 
        lifeless rocks floating endlessly in the vastness of the inky-black void!” 

        <br></br>
         The students of Class 5B presented their assembly on the topic, ‘Oceans of the World’ on Wednesday, 28 June 2023 and 
        Friday 30 June 2023 to mark the celebration of ‘World Ocean Day’ that is being observed on 8 June every year. 
        <br></br>
        The topic was introduced through a conversation that highlighted the crucial role played by the oceans in 
        maintaining life on Earth, followed by a small skit which showcased the impact of water pollution on marine life. 
        Students forewarned that plastic materials are the resources that are not being harvested and if dispersed, 
        destruction is inevitable to the countless marine life that has been a home for years. So, it was time to wake up 
        and be responsible for all our actions. An instrumental background music was played by a student using a 
        saxophone while the skit was being enacted in order to add essence to the presentation. The students pledged to 
        protect this vast, irreplaceable ecosystem that sustains us all.
        <br></br>
        “Humanity counts on the ocean. But could the ocean count on us?” The assembly concluded with this question 
        thereby making them think their actions.
        
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “In one drop of water, we find all the secrets of the Ocean; in one aspect of you, we find all the aspects of 
   existence. Beat Plastic, Save Ocean, Save Marine Life!”

<br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly5B2023;